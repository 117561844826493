:root {
  --color-platinum: #DCE0D9;
  --color-dark-purple: #31081F;
  --color-rosewood: #6B0F1A;
  --color-dark-gray: #595959;
  --color-light-gray: #808F85;
}

* {
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
}

body{
  background-color: var(--color-light-gray);
  margin: 0;
}

nav{
  background-color: var(--color-dark-gray);
  color: var(--color-platinum);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

nav ul{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav a{
  color:inherit;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  text-decoration: none;
  height: 100%;
}

.active{
  background-color: var(--color-rosewood);
}

nav li{
  float:left;
}

nav li:hover{
  background-color: var(--color-dark-purple);
}

.job{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
}

.doc-card{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
}

p{
  margin-left: 5%;
}

h1{
  margin-left: 2%;
}

h2{
  margin-left: 3%;
}

.home-list{
  list-style-type: none;
}

.home-list li{
  margin-top: 10px;
}

.home-card {
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
}

.contact-container{
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
  margin-left: 50px;
}

.contact-name{
  margin-left: 10px;
}

.contact-desc{

}

.contact-info-container{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
}

.about-container{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
}

.interest-container{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
}

.skill-container{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
}

.project-container{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
}

.project-image{
  margin-left: 30px;
}

.me-container{
  width: 30%;
  border: 2px solid;
  border-radius: 10px;
  border-color: var(--color-dark-purple);
  background-color: var(--color-platinum);
  margin-left: 2%;
  margin-top: 10px;
  padding: 2%;
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
}

#me{
  margin-left: 35%;
}